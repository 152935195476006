<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Litsenziya talablari</div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                v-model="filterForm.search"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <el-button @click="drawerCreate = true">Talablari qo'shish</el-button>
                    
                    <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            size="small"
                            icon="el-icon-setting"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(column, index) in columns"
                                :key="index"
                            >
                                <el-checkbox
                                    :checked="column.show"
                                    @change="column.show = !column.show"
                                    >{{ column.title }}</el-checkbox
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item> Litsenziya talablari </el-breadcrumb-item>
                <el-breadcrumb-item> Talablari </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.status.show">
                            {{ columns.status.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>
                        
                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th>
                            <span>Yaratmoq</span>
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.name"
                                :placeholder="columns.name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.status.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.status"
                                :placeholder="columns.status.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.created_at.show">
                            <el-date-picker
                                v-model="filterForm.created_at"
                                :placeholder="columns.created_at.title"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>
                      
                        <th v-if="columns.updated_at.show">
                            <el-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th> <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.status"
                                disabled
                            ></el-input>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr v-for="license_requirement in list" :key="license_requirement.id" class="cursor-pointer">
                        <td v-if="columns.id.show">{{ license_requirement.id }}</td>

                        <td v-if="columns.name.show">
                            {{ license_requirement.procedure ? license_requirement.procedure.name : '' }}
                        </td>

                        <td v-if="columns.status.show">
                            <span v-if="license_requirement.status">Faol</span>
                            <span v-else>No Faol</span>
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ license_requirement.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ license_requirement.updated_at }}
                        </td>

                        <td>
                            <router-link :to="{ path: '/licenseRequimentsAdd/' + license_requirement.id }">
                                <el-button
                                    class="style-link-prifil"
                                    type="success"
                                    size="small"
                                    plain
                                >
                                    <i class="el-icon-view"></i>
                                        <span> Yaratmoq </span>
                                    <i class="el-icon-right"></i>
                                </el-button>
                            </router-link>
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="license_requirement"
                                :permissionShow="'license_requirement@update'"
                                :permissionDestroy="'license_requirement@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            >
                            </crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="70%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import CrmCreate from "./components/crm-create";
import crmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
export default {
    name: "licenseRequirements",
    mixins: [list],
    components: {
        CrmCreate,
        crmUpdate
    },
    computed: {
        ...mapGetters({
            list: "licenseRequirements/list",
            columns: "licenseRequirements/columns",
            pagination: "licenseRequirements/pagination",
            filter: "licenseRequirements/filter",
            sort: "licenseRequirements/sort"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    data() {
        return {};
    },
     methods: {
        ...mapActions({
            updateList: "licenseRequirements/index",
            setPagination: "licenseRequirements/setPagination",
            updateSort: "licenseRequirements/updateSort",
            updateFilter: "licenseRequirements/updateFilter",
            updateColumn: "licenseRequirements/updateColumn",
            updatePagination: "licenseRequirements/updatePagination",
            show: "licenseRequirements/show",
            empty: "licenseRequirements/empty",
            delete: "licenseRequirements/destroy",
            refreshData: "licenseRequirements/refreshData",
        }),
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("licenseRequirements/EMPTY_LIST");
        next();
    },
};
</script>

