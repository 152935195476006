<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tibbiy Texnikalar talablari</div>
                </div>
            </el-col>
            <!-- <el-col :span="6" class="filter_table">
                <el-button @click="drawerCreate = true">Arizalar qo'shish</el-button>
            </el-col> -->
        </el-row>
        <table class="rez-khan-table" v-loading="loadingData">
            <thead>
                <tr>
                    <th>Texnikalar turi</th>
                    <th>Sozlamalar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{ item.technique_type_name }}</td>
                    <td>
                        <crm-settings
                            :name="$options.name"
                            :model="item"
                            :permissionShow="'license_requirement@update'"
                            :permissionDestroy="'license_requirement@destroy'"
                            :actions="actions"
                            @edit="edit"
                            @delete="destroy"
                            >
                        </crm-settings>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <div class="my-pagination">
            <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
        </div> -->

        <el-drawer
            :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')">
            <div>
                <techniquesUpdate
						ref="drawerUpdateChild"
						drawer="drawerUpdate"
						:selectedItem="selectedItem"
					 >
                </techniquesUpdate>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import techniquesUpdate from "./components/techniques-update";
export default {
    props: {
        activeName: {
            default: null,
            type: String
        },
        licenseParams:{
            type: Object,
            required: true
        },
    },
    components: {
        techniquesUpdate,
    },
    computed: {
        ...mapGetters({
            list: "licenseRequirementTechniques/list",
            columns: "licenseRequirementTechniques/columns",
            pagination: "licenseRequirementTechniques/pagination",
            filter: "licenseRequirementTechniques/filter",
            sort: "licenseRequirementTechniques/sort"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    watch:{
        $props: {
            handler: function(){
                if (this.activeName == 'techniques') {
                    this.fetchDataTechniques();
                }else{
                    this.empty();
                }
            },
            deep: true
        },
    //    'pagination.page': {
    //         handler: async function (newVal, oldVal) {
    //             if (newVal != oldVal && _.isFunction(this.fetchData)) {
    //                 this.fetchDataTechniques();
    //             }
    //         },
    //         deep: true
    //     },
    //     'pagination.per_page': {
    //         handler: async function (newVal, oldVal) {
    //             if (newVal != oldVal && _.isFunction(this.fetchData)) { 
    //                 this.fetchDataTechniques();
    //             }
    //         },
    //         deep: true
    //     },
    },
    data() {
        return {
            loadingData: false,
            reloadList: false,
            selectedItem: {},
            drawerUpdate: false
        };
    },
    methods: {
        ...mapActions({
            updateList: "licenseRequirementTechniques/index",
            setPagination: "licenseRequirementTechniques/setPagination",
            updateSort: "licenseRequirementTechniques/updateSort",
            updateFilter: "licenseRequirementTechniques/updateFilter",
            updateColumn: "licenseRequirementTechniques/updateColumn",
            updatePagination: "licenseRequirementTechniques/updatePagination",
            show: "licenseRequirementTechniques/show",
            empty: "licenseRequirementTechniques/empty",
            delete: "licenseRequirementTechniques/destroy",
            refreshData: "licenseRequirementTechniques/refreshData",
        }),
        fetchDataTechniques() { 
            if (!this.loadingData) {
                this.loadingData = true;
                this.show(this.licenseParams.id).then(res => {
                    this.loadingData = false;  
                }).catch(err => {
                    this.loadingData = false;
                });
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref){
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchDataTechniques();
                this.afterFetchData();
            }
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
        edit(model) {
            this.selectedItem = model;
            this.drawerUpdate = true;
        },
        destroy(model) {
        this.delete(model.id)
            .then(res => {
                this.$alert(res);
                this.fetchDataTechniques()
            })
            .catch(err => {
                this.$alert(err);
            })
        },
    }
};
</script>
