<template>
    <div>
        <el-tabs tab-position="left"  v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="Tibbiy xodimlar" name="doctors">
                <Employees :activeName="activeName" :licenseParams.sync="updateParams" :addOpend="opened"></Employees> 
            </el-tab-pane>
            <el-tab-pane label="Tibbiy Texnikalar" name="techniques">
                <Techniques :activeName="activeName" :licenseParams.sync="updateParams"></Techniques>
            </el-tab-pane>
            <el-tab-pane label="Tibbiy uskunalar" name="equipments">
                <Institutions :activeName="activeName" :licenseParams.sync="updateParams"></Institutions>
            </el-tab-pane>
        </el-tabs>  
    </div>
</template>
<script>
import Employees from "./components/employees-update.vue";
import Techniques from "./components/techniques-update.vue";
import Institutions from "./components/institutions-update.vue";
export default {
    name: "licenseRequirement",
    props: ['updateParams', 'opened'],
    components: {
        Employees,
        Techniques,
        Institutions
    },
	 watch: {
		opened: {
			handler: function (newVal) {
				if (!newVal) {
					this.activeName = 'doctors';
				}
			}
		}
	 },
    data() {
        return {
            activeName: 'doctors'
        };
    },
    methods: {
        handleClick(tab) {
            this.activeName = tab.name;
        },
    }
};
</script>

