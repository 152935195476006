<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Litsenziya tanlang" prop="license_procedure_id">
                            <select-license-procedures
                            v-model="form.license_procedure_id"
                            :id="form.license_procedure_id"
                            size="large"
                            >
                            </select-license-procedures>
                        </el-form-item>
                        <el-form-item :label="$t('message.status')">
                            <el-select v-model="form.status" class="w-100" :placeholder="$t('message.status')">
                                <el-option
                                    label="Faol"
                                    :value="true"
                                ></el-option>
                                <el-option
                                    label="No Faol"
                                    :value="false"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <add-update :opened.sync="has_opened" :updateParams.sync="selectedItem"></add-update>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import form from "../../../utils/mixins/form";
import drawer from '../../../utils/mixins/drawer';
import show from '../../../utils/mixins/show';
import addUpdate from '../add-update';
    export default {
  components: { 'add-update': addUpdate },
        mixins: [form, drawer, show],
		  props: {
				selectedItem: {
						type: Object
				}
			},
        computed:{
            ...mapGetters({
                model: "licenseRequirements/model",
                rules: "licenseRequirements/rules",
                columns: "licenseRequirements/columns"
            })
        },
        data() {
            return {
                has_opened: false,
            }
        },
        methods: {
            ...mapActions({
                save: 'licenseRequirements/update',
                show: "licenseRequirements/show",
            }),
            afterOpen(){
                this.fetchData();
                this.has_opened = true;
            },
            submit(close = true) {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            afterLeave(){
                this.$store.commit('licenseRequirements/EMPTY_MODEL');
                this.$store.commit('licenseRequirementsAdd/EMPTY_LIST');
                this.has_opened = false;
            }
        }
    }
  </script>