<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my" v-loading="loading">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item :label="$t('message.typesOfEmployees')" prop="type_of_employee_id">
                           <select-employee-type v-model="form.type_of_employee_id" :id="form.type_of_employee_id" size="medium"></select-employee-type>
                        </el-form-item>
                    </el-col> 
                    <el-col :span="8">
                        <el-form-item label="Soni (ta)">
                          
                            <el-col class="line" :span="2"></el-col>
                            <el-col :span="11">
                                <el-input-number  class="w-100"  v-model="form.li_until" :min="0" size="medium"></el-input-number>
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Xodimlar yo'nalishi" prop="direction_id">
                            <select-directions v-model="form.direction_id" :id="form.direction_id" size="medium"></select-directions>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Pasport seriyasi va raqami">
                            <el-input
                                size="medium"
                                placeholder="Pasport seriyasi va raqami"
                                :disabled="true">
                            </el-input>
                        </el-form-item>
                    </el-col>
                  
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.passport_status" size="medium"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="INN">
                            <el-input
                                size="medium"
                                placeholder="INN"
                                :disabled="true">
                            </el-input>
                        </el-form-item>
                    </el-col>
                 
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.inn_status" size="medium"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Viloyat">
                            <el-input
                                placeholder="Viloyat"
                                :disabled="true" size="medium">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.region_status" size="medium"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>
                
                <el-row :gutter="20" v-for="(item, index) in educations" :key="'educations-'+index">
                    <el-col :span="8">
                        <el-form-item label="Oliy o'quv yurti">
                            <select-higher-educations v-model="item.education_id" :id="item.education_id" size="medium" :disabled="item.dis_education"></select-higher-educations>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="Boshqarmoq">
                            <el-switch 
                                v-model="item.education_status"
                                @change="handleChange(item.education_status, 'education', index)">
                            </el-switch>
                        </el-form-item>
                    </el-col>   
                      <i
							 	v-show="!item.id"
                        class="delet-icon el-icon-circle-close"
                        @click="deleteEducation(index)"
                    ></i>
                </el-row>
                <div class="w-100">
                    <div class="add-form-educet">
                        <el-button
                            class="asosy-btn-d"
                            icon="el-icon-plus"
                            @click="addEducation()"
                        >
                            Qo'shish
                        </el-button>
                    </div>
                </div>  
            
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Ish Tajribasi(Necha yildan yilgacha)">
                            <el-col :span="11">
                                <el-input-number class="w-100" v-model="form.work_experience_from" :min="0" size="medium" :disabled="dis_work_experience"></el-input-number>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-input-number  class="w-100"  v-model="form.work_experience_until" :min="0" size="medium" :disabled="dis_work_experience"></el-input-number>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.work_experience_status" @change="handleChange(form.work_experience_status, 'work_experience')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row> 

                <el-row :gutter="20" v-for="(item, index) in categories" :key="'categories-'+index">
                    <el-col :span="8">
                        <el-form-item label="Malaka Toifasi">
                          <select-qualification-categorie v-model="item.qualification_category_id" :id="item.qualification_category_id" size="medium" :disabled="item.dis_qualification_category">
                          </select-qualification-categorie>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch 
                                v-model="item.qualification_category_status" 
                                @change="handleChange(item.qualification_category_status, 'category', index)">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <i
						  		v-show="!item.id"
                        class="delet-icon el-icon-circle-close"
                        @click="deleteQualificationCategory(index, item.id)"
                    ></i>   
                </el-row>
                <div class="w-100">
                    <div class="add-form-educet">
                        <el-button
                            class="asosy-btn-d"
                            icon="el-icon-plus"
                            @click="addQualificationCategory()"
                        >
                           Qo'shish
                        </el-button>
                    </div>
                </div>

                  <el-row :gutter="20" v-for="(item, index) in academic_degrees" :key="'academic_degrees-'+index">
                    <el-col :span="8">
                        <el-form-item label="Ilmiy Daraja qnday">
                            <select-academic-dgrees v-model="item.academic_degree_id" :id="item.academic_degree_id" size="medium" :disabled="item.dis_academic_degree"></select-academic-dgrees>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch 
                                v-model="item.academic_degree_status" 
                                @change="handleChange(item.academic_degree_status, 'academic_degree', index)">
                            </el-switch>
                        </el-form-item>
                    </el-col>   
                    <i
						      v-show="!item.id"
                        class="delet-icon el-icon-circle-close"
                        @click="deleteAcademicDegree(index, item.id)"
                    ></i>
                </el-row>
                <div class="w-100">
                    <div class="add-form-educet">
                        <el-button
                            class="asosy-btn-d"
                            icon="el-icon-plus"
                            @click="addAcademicDegree()">
                            Qo'shish
                        </el-button>
                    </div>
                </div> 

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Boshqaruvda">
                            <el-select class="w-100" v-model="form.steerage" placeholder="Boshqaruvda" size="medium" :disabled="dis_steerage" clearable>
                                <el-option label="Ha" :value="true"></el-option>
                                <el-option label="Yo'q" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.steerage_status" @change="handleChange(form.steerage_status, 'steerage')"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>

					<el-row :gutter="20">
						<el-col :span="8">
							<el-form-item label="Sertifikat ma'lumotlari (seriya va raqami, berilgan sana, amal qilish muddati)">
									<el-input
										size="medium"
										placeholder="Sertifikat ma'lumotlari"
										:disabled="true">
									</el-input>
							</el-form-item>
						</el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.certificate_status" size="medium"></el-switch>
                        </el-form-item>
                    </el-col>     
               </el-row>

					<el-row :gutter="20">
						<el-col :span="8">
							<el-form-item label="Diplom ma'lumotlari (seriya va raqami, berilgan sana)">
									<el-input
										size="medium"
										placeholder="Diplom ma'lumotlari"
										:disabled="true">
									</el-input>
							</el-form-item>
						</el-col>
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.diploma_status" size="medium"></el-switch>
                        </el-form-item>
                    </el-col>     
               </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import form from "../../../../utils/mixins/form";
import drawer from '../../../../utils/mixins/drawer';
    export default {
        mixins: [form, drawer],
		  props: ['selectedItem'],
        computed: {
            ...mapGetters({
                model: "licenseRequirementsAdd/model",
                rules: "licenseRequirementsAdd/rules",
                columns: "licenseRequirementsAdd/columns",
					 old_educations: 'licenseRequirementsAdd/educations',
					 old_categories: 'licenseRequirementsAdd/categories',
					 old_deegres: 'licenseRequirementsAdd/academic_deegres'
            })
        },
        data() {
            return {
                dis_work_experience: false,
                dis_steerage: false,
                educations: [],
                categories: [],
                academic_degrees: []
            }
        },
         methods: {
            ...mapActions({
						save: "licenseRequirementsAdd/update",
						editItems: "licenseRequirementsAdd/editItems"
            }),
            afterOpen(){
					this.load();
            },
				load(){
					if (!this.loading) {
						this.loading = true;
						this.editItems(this.selectedItem.id).then((res) => {
							this.loading = false;
							this.form = JSON.parse(JSON.stringify(this.model));
							this.educations = JSON.parse(JSON.stringify(this.old_educations));
							this.categories = JSON.parse(JSON.stringify(this.old_categories));
							this.academic_degrees = JSON.parse(JSON.stringify(this.old_deegres));

							if (!this.form.steerage) this.dis_steerage = true;
							if (!this.form.work_experience_status) this.dis_work_experience = true;

							if (_.isEmpty(this.old_educations)) {
								this.educations.push({
									id: null,
									education_id: null,
									education_status: null,
									dis_education: true
								});
							}

							if (_.isEmpty(this.old_categories)) {
								this.categories.push({
									id: null,
									qualification_category_id: null,
									qualification_category_status: null,
									dis_qualification_category: true
								});
							}

							if (_.isEmpty(this.old_deegres)) {
								this.academic_degrees.push({
									id: null,
									academic_degree_id: null,
									academic_degree_status: null,
									dis_academic_degree: true,
								});
							}

						})
						.catch((err) => {this.$alert(err)});
					}
				},
            submit(close = true) {

                let items = [];
                let data_multiselects = [];

                if (this.form.li_until) {
                    items.push({
                        name: 'type_of_employee',
                        value_type: 'li_interval',
                        until: this.form.li_until
                    });
                }
                if (this.form.passport_status) {
                    items.push({
                        name: 'passport_and_series',
                        li_status: this.form.passport_status
                    });
                }
                if (this.form.inn_status) {
                    items.push({
                        name: 'inn',
                        li_status: this.form.inn_status
                    });
                }
                if (this.form.region_status) {
                    items.push({
                        name: 'region',
                        li_status: this.form.region_status
                    });
                }
                if (this.form.work_experience_status) {
                    items.push({
                        name: 'work_experience',
                        value_type: 'li_interval',
                        li_status: this.form.work_experience_status,
                        data: {
                            from: this.form.work_experience_from,
                            until: this.form.work_experience_until
                        }
                    });
                }

                let data1 = [];
                this.educations.forEach(elem => {
                    if (elem.education_id && elem.education_status) {
                        data1.push({
                            source_id: elem.education_id,
                            status: elem.education_status 
                        });
                    }
                });
                if (!_.isEmpty(data1)) {
                    data_multiselects.push({
                        name: 'educations',
                        value_type: 'li_multiselect',
                        items: data1
                    });
                }

                let data2 = [];
                this.categories.forEach(elem => {
                    if (elem.qualification_category_id && elem.qualification_category_status) {
                        data2.push({
                            source_id: elem.qualification_category_id,
                            status: elem.qualification_category_status  
                        });
                    }
                });
                if (!_.isEmpty(data2)) {
                    data_multiselects.push({
                        name: 'qualification_category',
                        value_type: 'li_multiselect',
                        items: data2
                    });
                }
                
                let data3 = [];
                this.academic_degrees.forEach(elem => {
                    if (elem.academic_degree_id && elem.academic_degree_status) {
                        data3.push({
                            source_id: elem.academic_degree_id,
                            status: elem.academic_degree_status
                        });
                    }
                });
                if (!_.isEmpty(data3)) {
                    data_multiselects.push({
                        name: 'academic_degree',
                        value_type: 'li_multiselect',
                        items: data3
                    });
                }

                if (this.form.steerage_status){
                    items.push({
                        li_type: this.form.steerage,
                        name: 'steerage',
                        value_type: 'li_boolean',
                        li_status: this.form.steerage_status
                    });
                }

					if (this.form.certificate_status) {
						items.push({
							name: 'certificate',
							li_status: this.form.certificate_status
						});		
					}

					if (this.form.diploma_status) {
						items.push({
							name: 'diploma',
							li_status: this.form.diploma_status
						});
					}

               this.form["items"] = items;

                data_multiselects.forEach((elem, index) => {
                    if (_.isEmpty(elem.items)) {
                        delete data_multiselects.splice(index, 1);
                    }
                });
                this.form["data_multiselects"] = data_multiselects;

                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            addEducation(){
               this.educations.push({
						  id: null,
                    education_id: null,
                    education_status: null,
                    dis_education: true
                });
            },
            addQualificationCategory(){
					this.categories.push({
						id: null,
						qualification_category_id: null,
						qualification_category_status: null,
						dis_qualification_category: true
					});
            },
            addAcademicDegree(){
					this.academic_degrees.push({
						id: null,
						academic_degree_id: null,
						academic_degree_status: null,
						dis_academic_degree: true,
					});
            },

            handleChange(status, type_name, place_index){
                if (type_name == 'education') {
                    this.educations.map((item, index) => {
                        if (index == place_index) {
                            item.dis_education = (item.dis_education) ? false : true;
                        }
                    });
                }
                else if (type_name == 'work_experience'){this.dis_work_experience = (status) ? false : true}
                else if (type_name == 'category'){
                    this.categories.forEach((elem, index) => {
                        if (place_index == index) {
                            elem.dis_qualification_category = (elem.dis_qualification_category) ? false : true;
                        }
                    });
                }
                else if (type_name == 'academic_degree') {
                    this.academic_degrees.forEach((elem, index) => {
                        if (place_index == index) {
                            elem.dis_academic_degree = (elem.dis_academic_degree) ? false : true;
                        }
                    });
                }
                else if (type_name == 'steerage') {this.dis_steerage = (status) ? false : true}
            },

            deleteEducation(index){
					if (!_.isEmpty(this.old_educations)) {
						delete this.educations.splice(index, 1);
					}
					else if (index != 0) { delete this.educations.splice(index, 1); } 
            },
            deleteQualificationCategory(index){
					if (!_.isEmpty(this.old_categories)) {
						delete this.categories.splice(index, 1);
					}
					else if (index != 0) { delete this.categories.splice(index, 1); }
            },
            deleteAcademicDegree(index){
					if (!_.isEmpty(this.old_academic_degrees)) {
						delete this.academic_degrees.splice(index, 1);
					}
					else if (index != 0){
						delete this.academic_degrees.splice(index, 1);
					}
            },
            
            afterLeave(){
                this.$store.commit('licenseRequirementsAdd/EMPTY_MODEL');
                this.$store.commit('licenseRequirementsAdd/EMPTY_ITEMS');
                this.educations = [];
                this.categories = [];
                this.academic_degrees = [];
                this.dis_work_experience = false;
                this.dis_steerage = false;
            }
        }
    }
</script>
<style>
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
    cursor: pointer;
}
</style>