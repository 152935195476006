<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tibbiy Uskunalar talablari</div>
                </div>
            </el-col>
            <!-- <el-col :span="6" class="filter_table">
                <el-button @click="drawerCreate = true">Arizalar qo'shish</el-button>
            </el-col> -->
        </el-row>
        <table class="rez-khan-table" v-loading="loadingData">
            <thead>
                <tr>
                    <th>Uskunalar turi</th>
                    <th>Sozlamalar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{ item.equipment_type_name }}</td>
                    <td>
                        <crm-settings
                            :name="$options.name"
                            :model="item"
                            :permissionShow="'license_requirement@update'"
                            :permissionDestroy="'license_requirement@destroy'"
                            :actions="actions"
                            @edit="edit"
                            @delete="destroy"
                        >
                        </crm-settings>
                    </td>
                </tr>
            </tbody>
        </table>

        <el-drawer
            :append-to-body="true"
				:with-header="false"
				:visible.sync="drawerUpdate"
				:wrapperClosable="false"
				size="95%"
				ref="drawerUpdate"
				@opened="drawerOpened('drawerUpdateChild')"
				@closed="drawerClosed('drawerUpdateChild')">
            <div>
					<equipmentUpdate
					ref="drawerUpdateChild"
					drawer="drawerUpdate"
					:selectedItem="selectedItem"
					>
					</equipmentUpdate>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import _ from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import equipmentUpdate from "./components/institutions-update";
export default {
    props: {
        activeName: {
            default: null,
            type: String
        },
        licenseParams:{
            type: Object,
            required: true
        },
    },
    components: {
        equipmentUpdate,
    },
    computed: {
        ...mapGetters({
            list: "licenseRequirementEquipments/edit_list",
            columns: "licenseRequirementEquipments/columns",
            pagination: "licenseRequirementEquipments/pagination",
            filter: "licenseRequirementEquipments/filter",
            sort: "licenseRequirementEquipments/sort"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    watch:{
        $props: {
            handler: function(){
                if (this.activeName == 'equipments') {
                    this.fetchDataEquipments();
                }else{
                    this.empty();
                }
            },
            deep: true
        },
    //    'pagination.page': {
    //         handler: async function (newVal, oldVal) {
    //             if (newVal != oldVal && _.isFunction(this.fetchData)) {
    //                 this.fetchData();
    //             }
    //         },
    //         deep: true
    //     },
    //     'pagination.per_page': {
    //         handler: async function (newVal, oldVal) {
    //             if (newVal != oldVal && _.isFunction(this.fetchData)) { 
    //                 this.fetchData();
    //             }
    //         },
    //         deep: true
    //     },
    },
    data() {
        return {
            loadingData: false,
            reloadList: false,
            selectedItem: {},
            drawerUpdate: false
        }
    },
    methods: {
        ...mapActions({
            updateList: "licenseRequirementEquipments/index",
            setPagination: "licenseRequirementEquipments/setPagination",
            updateSort: "licenseRequirementEquipments/updateSort",
            updateFilter: "licenseRequirementEquipments/updateFilter",
            updateColumn: "licenseRequirementEquipments/updateColumn",
            updatePagination: "licenseRequirementEquipments/updatePagination",
            show: "licenseRequirementEquipments/show",
            empty: "licenseRequirementEquipments/empty",
            delete: "licenseRequirementEquipments/destroy",
            refreshData: "licenseRequirementEquipments/refreshData",
        }),
        fetchDataEquipments() { 
            if (!this.loadingData) {
                this.loadingData = true;
                this.show(this.licenseParams.id).then(res => {
                    this.loadingData = false;   
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref){
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchDataEquipments();
                this.afterFetchData();
            }
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
        edit(model) {
            this.selectedItem = model;
            this.drawerUpdate = true;
        },
        destroy(model) {
        this.delete(model.id)
            .then(res => {
                this.$alert(res);
                this.fetchDataEquipments()
            })
            .catch(err => {
                this.$alert(err);
            })
        },
    }
};
</script>
